import { classes } from 'minds-react-sdk';
import { PropsWithChildren } from 'react';
import style from './subtitle.module.scss';

interface Props extends PropsWithChildren {
    color?: string;
    className?: string;
    lineHeight?: string | number;
}

export default function Subtitle(props: Props) {
    return (
        <h2
            className={classes([style.subtitle, props.className])}
            style={{ color: props.color, lineHeight: props.lineHeight }}
        >
            {props.children}
        </h2>
    );
}
