import { Button, c, CenteredLoading, Icon, IconName } from 'minds-react-sdk';
import {
    ChangeEvent,
    DragEvent,
    forwardRef,
    Ref,
    useRef,
    useState,
} from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import style from './file-input.module.scss';

export interface Props {
    id: string;
    onChange: (file?: File) => unknown;
    invalid?: boolean;
    acceptedTypes?: string[];
    defaultIcon?: IconName;
    removeText?: string;
    loading?: boolean;
}

export interface PropsWithControl<T extends FieldValues>
    extends Omit<Props, 'onChange'> {
    control: Control<T>;
    name: Path<T>;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const FileInputImplementation = forwardRef(
    (props: Props, ref: Ref<HTMLLabelElement>) => {
        const [currentFile, setCurrentFile] = useState<File | undefined>();
        const { t } = useTranslation();
        const inputRef = useRef<HTMLInputElement>(null);

        function handleChange(file?: File) {
            if (!file) {
                setCurrentFile(undefined);
                props.onChange(undefined);
                return;
            }

            setCurrentFile(file);
            props.onChange(file);
        }

        function handleFile(e: ChangeEvent<HTMLInputElement>) {
            handleChange(e.target.files?.[0]);
        }

        function handleDrop(e: DragEvent<HTMLLabelElement>) {
            e.preventDefault();

            handleChange(e.dataTransfer.files?.[0]);
        }

        function handleDrag(e: DragEvent<HTMLLabelElement>) {
            e.preventDefault();
        }

        function handleDelete(
            e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        ) {
            e.preventDefault();
            handleChange(undefined);
            if (inputRef.current) inputRef.current.value = '';
        }

        return (
            <label
                htmlFor={props.id}
                className={c(style.fileInput, props.invalid && style.invalid)}
                onDrop={handleDrop}
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                data-testid="fileUploadInput"
                ref={ref}
            >
                {props.loading ? (
                    <CenteredLoading size="2x" />
                ) : (
                    <>
                        <div className="flex justify-center">
                            {props.defaultIcon ? (
                                <Icon
                                    name={props.defaultIcon}
                                    className={c(
                                        style.imgIcon,
                                        currentFile && style.withFile,
                                    )}
                                />
                            ) : null}
                        </div>

                        <div className={style.divFormats}>
                            <span
                                className={c(
                                    style.sentence,
                                    currentFile && style.withFile,
                                )}
                            >
                                {currentFile?.name ??
                                    t('file_uploader_instruction')}
                            </span>
                            <input
                                ref={inputRef}
                                type="file"
                                name=""
                                id={props.id}
                                onChange={handleFile}
                                accept={props.acceptedTypes?.join(',')}
                                data-testid={props.id}
                            />
                            {!currentFile ? (
                                <>
                                    <span className={style.button}>
                                        {t('file_uploader_button')}
                                    </span>
                                    <span
                                        className={c(
                                            props?.acceptedTypes?.length ??
                                                0 > 3
                                                ? style.formatsLong
                                                : style.formats,
                                        )}
                                    >
                                        {props.acceptedTypes
                                            ?.map((type) => type.toUpperCase())
                                            .join(', ')}
                                    </span>
                                </>
                            ) : null}
                            {props.removeText && currentFile ? (
                                <Button
                                    className={style.btnRemove}
                                    content={props.removeText}
                                    onClick={handleDelete}
                                />
                            ) : null}
                        </div>
                    </>
                )}
            </label>
        );
    },
);

export default function FileInput<T extends FieldValues>(
    props: PropsWithControl<T>,
) {
    return (
        <Controller
            name={props.name}
            control={props.control}
            render={({ field }) => (
                <FileInputImplementation {...field} {...props} />
            )}
        />
    );
}
