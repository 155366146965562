import { c, classes } from 'minds-react-sdk';
import React, { forwardRef } from 'react';
import styles from './textArea.module.scss';

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    label?: string;
    valid?: boolean;
    noResize?: boolean;
}

function TextArea(
    { label, valid, noResize, ...props }: Props,
    ref: React.ForwardedRef<HTMLTextAreaElement>,
) {
    const inputClasses = classes([styles.input, props.className]);

    return (
        <>
            {label ? (
                <label htmlFor={props.id} className={styles.label}>
                    {label}
                </label>
            ) : null}
            <textarea
                {...props}
                ref={ref}
                className={c(
                    inputClasses,
                    valid === false && styles.invalid,
                    noResize && styles.noResize,
                )}
                data-testid={props.id}
            />
        </>
    );
}

export default forwardRef(TextArea);
