import { DocumentTypes } from '@helpers/flagsConstants';
import { getIsoCountryCode } from '@helpers/i18n';
import { getDocumentFromCode } from '@ronaldarndt/nationalid';
import { getUserFromStorage } from '@stateAtoms/userAtom';
import i18next from 'i18next';
import { CountryCallingCode, formatNumber } from 'libphonenumber-js';
import { camelCase } from 'tiny-case';
import { CamelCasedPropertiesDeep } from 'type-fest';

const IGNORED_KEYS = ['@', '[', ']'];

export function clearMask(value?: string) {
    return value?.replace(/[^0-9]/g, '') ?? '';
}

export function currencyMask(data: number) {
    return data.toLocaleString(i18next.language, {
        style: 'currency',
        currency: 'BRL',
    });
}

export function transformObjectCasing(
    obj: unknown,
    transformFn: (key: string) => string,
): unknown {
    if (Array.isArray(obj)) {
        return obj.map((v: unknown) => transformObjectCasing(v, transformFn));
    }

    if (
        !obj ||
        typeof obj !== 'object' ||
        (typeof vi !== 'undefined' && 'd' in obj)
    ) {
        return obj;
    }

    const entries = Object.entries(obj).map(([key, v]) => [
        IGNORED_KEYS.some((i) => key.includes(i)) || isGuid(key)
            ? key
            : transformFn(key),
        transformObjectCasing(v as object, transformFn),
    ]);

    return Object.fromEntries(entries);
}

export function toCamelCaseObject<T>(obj: T): CamelCasedPropertiesDeep<T> {
    return transformObjectCasing(obj, camelCase) as CamelCasedPropertiesDeep<T>;
}

export function removeObjectEmptyFields(data: object) {
    return Object.fromEntries(
        Object.entries(data).filter(
            ([, v]) => v != null && v !== '' && v !== undefined,
        ),
    );
}

export function isGuid(value: string): boolean {
    const guidRegex =
        /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
    return guidRegex.test(value);
}

export function phoneMask(
    phone: string | undefined,
    phoneCountryCode: number | CountryCallingCode | undefined,
) {
    if (!phone) return '';

    const defaultPhoneCountryCode =
        getUserFromStorage()?.companyInfo?.defaultPhoneCountryCode || 55;

    const normalizedPhoneCountryCode = Number(phoneCountryCode?.toString());

    const countryCode =
        normalizedPhoneCountryCode === 1
            ? 'US'
            : getIsoCountryCode(
                  `${phoneCountryCode ?? defaultPhoneCountryCode}${phone}`,
              );

    const format =
        normalizedPhoneCountryCode === defaultPhoneCountryCode
            ? 'NATIONAL'
            : 'INTERNATIONAL';

    return formatNumber(phone, countryCode ?? 'BR', format);
}

export function personIdMask(
    personId: string | undefined | null,
    documentType: DocumentTypes | undefined,
) {
    if (!personId) return '';

    const personIdType =
        documentType ??
        getUserFromStorage()?.companyInfo?.defaultDocumentType ??
        'cpf';

    const formatter = getDocumentFromCode(personIdType);

    if (formatter.validate(personId)) {
        return formatter.format(personId);
    }

    return getDocumentFromCode('generic').format(personId);
}
