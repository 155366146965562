import { t } from 'i18next';
import { FieldError } from 'react-hook-form';
import { setLocale } from 'yup';

export function configureYup() {
    setLocale({
        mixed: {
            required: t('validation.required'),
        },
        string: {
            email: t('validation.email'),
            url: t('validation.url'),
            min: (c) => t('validation.min', { count: c.min }),
            max: (c) => t('validation.max', { count: c.max }),
            uuid: t('validation.uuid'),
        },
        number: {
            positive: t('validation.positive'),
        },
    });
}

export function isFieldValid(error?: FieldError, isDirty?: boolean) {
    return !isDirty ? undefined : !error?.message;
}
