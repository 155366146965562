import { c } from 'minds-react-sdk';
import { ForwardedRef, forwardRef, ImgHTMLAttributes } from 'react';
import logoBlack from '../../../assets/logos/logo-black.svg';
import logoWhite from '../../../assets/logos/logo-white.svg';
import style from './logo.module.scss';

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
    variant?: 'black' | 'white';
    size?: 'small' | 'medium' | 'large';
}

function Logo(
    { size = 'medium', variant, ...props }: Props,
    ref: ForwardedRef<HTMLImageElement>,
) {
    const src = variant === 'white' ? logoWhite : logoBlack;

    return (
        <img
            {...props}
            ref={ref}
            data-testid="logo"
            src={src}
            alt="Logo Minds Digital"
            className={c(
                size === 'small' && style.small,
                size === 'large' && style.large,
                size === 'medium' && style.medium,
                props.className,
            )}
        />
    );
}

export default forwardRef(Logo);
