import { Environment, UserProfiles } from '@apis/common';
import { LoginRequest } from '@apis/requests/auth/login-request';
import { DataResponse } from '@apis/responses/data-response';
import { MixpanelUserProperties, useMixpanel } from '@hooks/useMixpanel';
import useUser, { useUserService } from '@hooks/useUser/useUser';
import { useAtom, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { LoginResponse, UserClaims } from '../../helpers/auth';
import { authAtom } from '../../stateAtoms/authAtom';
import userAtom from '../../stateAtoms/userAtom';
import useRequest from '../useRequest';

export default function useAuth() {
    const setUser = useSetAtom(userAtom);
    const [auth, setAuth] = useAtom(authAtom);
    const { i18n } = useTranslation();

    const mixpanel = useMixpanel();
    const api = useRequest('auth');
    const userService = useUserService();
    const user = useUser();

    function authorizeUser(
        tokenData: DataResponse<UserClaims>,
        rememberMe = true,
        setAtoms = true,
    ) {
        if (!tokenData.success || !tokenData.data?.id) return false;

        if (setAtoms) {
            setUser(tokenData.data);
            setAuth((a) => ({
                ...a,
                rememberMe,
                isLoggedIn: true,
            }));

            userService.updateProfilePictureExpiration();

            const mixpanelData: MixpanelUserProperties = {
                id: tokenData.data.id,
                $email: tokenData.data.email,
                $name: tokenData.data.name,
                companyId: tokenData.data.companyId,
                companyName: tokenData.data.companyName,
                environment: tokenData.data.environment,
            };

            mixpanel.login(mixpanelData);
        }

        return true;
    }

    function getEnvPrefix(env: Environment) {
        if (env === Environment.Staging) return 'staging-';
        if (env === Environment.Sandbox) return 'sandbox-';
        return '';
    }

    async function login(
        data: LoginRequest,
        rememberMe = true,
        setAtoms = true,
    ): Promise<boolean> {
        const token = await api.post<LoginResponse>('login', {
            ...data,
            rememberMe,
        });

        const loggedIn = authorizeUser(
            { ...token, data: token.data?.userData ?? ({} as UserClaims) },
            rememberMe,
            setAtoms,
        );

        if (token.data?.redirectToEnv) {
            const url = new URL(document.location.href);
            const newPrefix = getEnvPrefix(token.data.redirectToEnv);

            url.hostname = `${newPrefix}${url.hostname
                .split('-')
                .slice(-1)
                .join('-')}`;

            window.location.assign(url);
        }

        void i18n.changeLanguage(
            token.data?.userData?.companyInfo?.defaultLanguage ?? 'pt',
        );

        return loggedIn;
    }

    async function trialTokenLogin(token: string) {
        const response = await api.get<UserClaims>('trial-token-login', {
            token,
        });

        return authorizeUser(response, false, true);
    }

    async function checkUserEnvironment() {
        if (user.profile !== UserProfiles.Master) return;

        const resp = await api.get<UserClaims>('me');

        if (
            user.companyId !== resp.data?.companyId ||
            user.environment !== resp.data?.environment
        ) {
            authorizeUser(resp);
        }
    }

    async function changeCompany(companyId: number) {
        const resp = await api.post<UserClaims>('change-company', {
            companyId,
            rememberMe: auth.rememberMe,
        });

        const success = authorizeUser(resp, auth.rememberMe, true);

        if (success) {
            document.location.reload();
        }
    }

    return {
        authorizeUser,
        login,
        trialTokenLogin,
        changeCompany,
        checkUserEnvironment,
        isLoggedIn: auth.isLoggedIn,
    };
}
