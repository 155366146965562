import { ForgotPasswordRequest } from '@apis/requests/auth/forgot-password-request';
import { ResetPasswordRequest } from '@apis/requests/auth/reset-password-request';
import { GetManageUsersRequest } from '@apis/requests/manage-users/get-manage-users-results';
import { UpdateUserRequest } from '@apis/requests/manage-users/update-user-request';
import { EditProfileRequest } from '@apis/requests/users/edit-profile-request';
import { ChangeBlocklistOrWhitelistResponse } from '@apis/responses/users/change-blocklist-or-whitelist';
import { EditProfileResponse } from '@apis/responses/users/edit-profile-response';
import { GetCompanyLogosResponse } from '@apis/responses/users/get-company-logos-response';
import { ListUsersResponse } from '@apis/responses/users/list-users-response';
import { UserDropdownResponse } from '@apis/responses/users/user-dropdown-response';
import { formatDates } from '@helpers/requests';
import { removeObjectEmptyFields } from '@helpers/transformations';
import { pascalCase } from 'tiny-case';
import useRequest from '../../useRequest';

export default function useUsers() {
    const api = useRequest('users');

    function put(id: number, data: EditProfileRequest) {
        const formattedRequest = removeObjectEmptyFields(data);
        return api.put<EditProfileResponse>(id.toString(), formattedRequest);
    }

    function getSignedProfilePicture() {
        return api.get<string>('sign-profile-picture');
    }

    function listUsers(
        { endDate, startDate, sort, ...request }: GetManageUsersRequest,
        signal: AbortSignal,
    ) {
        const formattedRequest = {
            ...request,
            ...formatDates({ startDate, endDate }, 'yyyy-MM-dd'),
            sort: sort ? pascalCase(sort) : undefined,
        };

        return api.getPaged<ListUsersResponse>('', formattedRequest, {
            signal,
        });
    }

    function exportUsers({
        endDate,
        startDate,
        sort,
        ...request
    }: GetManageUsersRequest) {
        const formattedRequest = {
            ...request,
            ...formatDates({ startDate, endDate }, 'yyyy-MM-dd'),
            sort: sort ? pascalCase(sort) : undefined,
        };

        return api.get<string>('export/csv', formattedRequest);
    }

    function getUserDropdown() {
        return api.get<UserDropdownResponse>('dropdown');
    }

    function patch(id: number, data: Partial<UpdateUserRequest>) {
        return api.put<ListUsersResponse>(`${id}/patch`, data);
    }

    function changeBlocklistOrWhitelist(
        data: ChangeBlocklistOrWhitelistResponse,
    ) {
        return api.put<ChangeBlocklistOrWhitelistResponse>('feature', data);
    }

    function resetPassword(data: ResetPasswordRequest) {
        return api.put('password', {
            token: data.token.replaceAll(' ', '+'),
            password: data.password,
            passwordConfirmation: data.passwordConfirmation,
        });
    }

    function forgotPassword(data: ForgotPasswordRequest) {
        return api.post('forgot-password', data);
    }

    function getCompanyLogos() {
        return api.get<GetCompanyLogosResponse>('sign-company-logos');
    }

    function getForgotPasswordUrl(userId: number) {
        return api.get<string>(`${userId}/forgot-password-url`);
    }

    return {
        put,
        getSignedProfilePicture,
        listUsers,
        exportUsers,
        patch,
        changeBlocklistOrWhitelist,
        resetPassword,
        forgotPassword,
        getUserDropdown,
        getCompanyLogos,
        getForgotPasswordUrl,
    };
}
