import useTrial from '@hooks/requests/useTrial';
import useUser from '@hooks/useUser';
import { useGet } from 'minds-react-sdk';

export default function useTrialGuard() {
    const { companyId } = useUser();

    const { getTrial } = useTrial();

    const [info, loading] = useGet(() => getTrial(companyId), {
        enabled: !!companyId,
    });

    const allow =
        info?.active &&
        (!info.closingDate || new Date(info.closingDate) > new Date());

    return [!!allow, loading] as const;
}
