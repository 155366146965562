import { classes } from 'minds-react-sdk';
import { PropsWithChildren } from 'react';
import style from './backdrop.module.scss';

interface Props extends PropsWithChildren {
    onClick?: () => unknown;
    className?: string;
}

export function Backdrop(props: Props) {
    return (
        <div
            data-testid="backgroundModal"
            className={classes([style.backdrop, 'backdrop', props.className])}
            onClick={() => props.onClick?.()}
        >
            {props.children}
        </div>
    );
}
