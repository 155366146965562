/* eslint-disable no-await-in-loop */
import { NOT_FOUND_PATH } from '@helpers/constants';
// eslint-disable-next-line import/no-cycle
import {
    getChildRoutes,
    getDefinitionFromPath,
    PROTECTED_ROUTES,
    RouteDefinition,
} from '@helpers/routeDefinitions';
import useUserPermission from '@hooks/useUserPermission';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export default function useTryNavigate() {
    const navigate = useNavigate();
    const { featuresLoadedAndIsAllowedAsync, isAllowed } = useUserPermission();

    const tryNavigateAsync = useCallback(
        async (path: string) => {
            const route = getDefinitionFromPath(path);

            if (
                await featuresLoadedAndIsAllowedAsync(
                    path,
                    route.profilePermission,
                    route.featurePermission,
                )
            ) {
                navigate(path);
                return;
            }

            const firstAllowed = getFirstAllowedRoute();

            navigate(firstAllowed ?? NOT_FOUND_PATH);
        },
        [isAllowed],
    );

    const getFirstAllowedRoute = useCallback(
        (root: RouteDefinition = PROTECTED_ROUTES): string | null => {
            const routes = getChildRoutes(root);

            for (const r of routes) {
                const allowed = isAllowed(
                    r.path,
                    r.profilePermission,
                    r.featurePermission,
                );

                if (allowed) {
                    return r.path ?? null;
                }
            }

            return null;
        },
        [isAllowed],
    );

    return tryNavigateAsync;
}
