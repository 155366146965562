import { getDefinitionFromPath } from '@helpers/routeDefinitions';
import { useMixpanel } from '@hooks/useMixpanel';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

export default function MainLayout() {
    const mixpanel = useMixpanel();
    const location = useLocation();

    useEffect(() => {
        const pageDefinition = getDefinitionFromPath(location.pathname);

        if (pageDefinition?.mixpanelName)
            mixpanel.track(pageDefinition.mixpanelName);
    }, [location]);

    return <Outlet />;
}
