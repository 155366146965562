import { CreateZendeskTicket } from '@apis/requests/zendesk/create-ticket';
import useRequest from '@hooks/useRequest';

export default function useZendesk() {
    const api = useRequest('zendesk');

    async function createTicket(data: CreateZendeskTicket) {
        const request = new FormData();
        request.append('name', data.name);
        request.append('email', data.email);
        request.append('subject', data.subject);
        request.append('description', data.description);
        request.append('file', data.file as File);

        return api.post('ticket', request);
    }

    return { createTicket };
}
