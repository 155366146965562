import NotificationsProviderContainer from '@components/cells/notifications-provider-container';
import ZendeskPopover from '@components/molecules/zendesk-popover/zendesk-popover';
import configureI18n from '@helpers/i18n';
import { configureYup } from '@helpers/yup';
import { store } from '@stateAtoms/store';
import styles from '@styles/styles';
// eslint-disable-next-line import/no-extraneous-dependencies
import GlobalModalProvider from '@components/cells/global-modal-provider';
import useAuth from '@hooks/useAuth';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import '@unocss/reset/normalize.css';
import { FeatureToggleProvider } from 'feature_toggle_react_client';
import { Provider } from 'jotai';
import { StyleProvider } from 'minds-react-sdk';
import mixpanel from 'mixpanel-browser';
import { useState } from 'react';
import 'react-day-picker/dist/style.css';
import ReactDOM from 'react-dom/client';
import Routes from 'routes';
// eslint-disable-next-line import/no-unresolved
import 'virtual:uno.css';
import { Config, loadConfig } from './helpers/config';
import './styles/styles.scss';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: (count) => count < 3,
            staleTime: 1000 * 5,
        },
    },
});

function App({ config }: { config: Config }) {
    const [loadedI18n, setLoadedI18n] = useState(false);

    if (!loadedI18n) {
        void configureI18n().then(() => {
            configureYup();
            setLoadedI18n(true);
        });
    }

    return (
        <Provider store={store}>
            <FeatureToggleProvider
                url={config.featureToggleUrl}
                environment={config.env}
                queryClient={queryClient}
            >
                <StyleProvider styles={styles}>
                    <NotificationsProviderContainer />
                    <GlobalModalProvider />

                    <QueryClientProvider client={queryClient}>
                        {loadedI18n ? <InnerApp /> : null}
                    </QueryClientProvider>
                </StyleProvider>
            </FeatureToggleProvider>
        </Provider>
    );
}

function InnerApp() {
    const [loadedAuth, setLoadedAuth] = useState(false);
    const auth = useAuth();

    if (!loadedAuth) {
        void auth.checkUserEnvironment().then(() => {
            setLoadedAuth(true);
        });
    }

    return loadedAuth ? (
        <>
            <Routes />

            <ZendeskPopover />
        </>
    ) : null;
}

window.global ||= window;

void loadConfig().then((config) => {
    mixpanel.init(config.mixpanelToken);

    ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
        <App config={config} />,
    );
});
