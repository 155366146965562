import { classes } from 'minds-react-sdk';
import { PropsWithChildren } from 'react';
import style from './title.module.scss';

interface Props extends PropsWithChildren {
    color?: string;
    className?: string;
}

export default function Title(props: Props) {
    return (
        <h1
            className={classes([style.title, props.className])}
            style={{ color: props.color }}
        >
            {props.children}
        </h1>
    );
}
