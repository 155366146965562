import { css } from '@emotion/react';
import { color, COLORS } from '@helpers/constants';
import i18n from 'i18next';
import { Styles } from 'minds-react-sdk';
import { snakeCase } from 'tiny-case';
import { transformObjectCasing } from '../helpers/transformations';

const colors = transformObjectCasing(
    COLORS,
    snakeCase,
) as Styles['common']['colors'];

const styles: Styles = {
    common: {
        colors: {
            ...colors,
            primary: color('primary'),
        },
    },
    button: css`
        color: #fff;
        border: none;
        border-radius: 10px;
        font-weight: 400;
        height: 3.1rem;
        line-height: 12px;
        padding: 8px;

        .left {
            margin-right: 5px;
        }
    `,
    iconButton: {
        borderRadius: '5px',
    },
    t: i18n.t.bind(i18n),
};

export default styles;
