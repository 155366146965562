import { atomWithStorage } from 'jotai/utils';
import { UserClaims } from '../helpers/auth';

export const userAtomKey = '__user_webportal_' + window.location.hostname;

const userAtom = atomWithStorage<UserClaims | null>(userAtomKey, null);

export function getUserFromStorage() {
    const user = localStorage.getItem(userAtomKey);

    if (user) {
        return JSON.parse(user) as UserClaims;
    }

    return null;
}

export default userAtom;
