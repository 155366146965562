import { Environment } from '@apis/common';
import mixpanel from 'mixpanel-browser';

export interface MixpanelUserProperties {
    id: number;
    $name: string;
    $email: string;
    companyId: number;
    companyName: string;
    environment: Environment;
}

export enum MixpanelEvents {
    Login = 'Login',
    Logout = 'Logout',
    PageView = 'Page View',
    AudioPlayed = 'Audio Played',
    ViewLogin = 'View Login',
    ViewForgotPassword = 'View Forgot Password',
    ViewResetPassword = ' View Reset Password',
    ViewConfirmAccount = ' View Confirm Account',
    ViewAzureLogin = ' View Azure Login',
    ViewNotFound = ' View Not Found',
    ViewTrial = 'View Trial',
    ViewDashboard = ' View Dashboard',
    ViewOperationsSearch = ' View Operations Search',
    ViewClientSearch = ' View Client Search',
    ViewClientSearchHistory = ' View Client Search History',
    ViewPhoneSearch = ' View Phone Search',
    ViewAlertList = ' View Alert List',
    ViewAlertDetails = ' View Alert Details',
    ViewFlagsFinishAnalysis = ' View Flag Finish Analysis',
    ViewCertifiedBiometrics = ' View Certified Biometrics',
    ViewCertifiedBiometricsInsert = ' View Certified Biometrics Insert',
    ViewCertifiedBiometricsImportInsert = ' View Certified Biometrics Import Insert',
    ViewCertifiedBiometricsImportRemoval = ' View Certified Biometrics Import Removal',
    ViewBlocklist = ' View Blocklist',
    ViewBlocklistPhones = ' View Blocklist Phones',
    ViewInsertBlocklistPhones = ' View Insert Blocklist Phones',
    ViewBlocklistVoices = ' View Blocklist Voices',
    ViewInsertBlocklistVoices = ' View Insert Blocklist Voices',
    ViewSilencedList = ' View Silenced List',
    ViewSilencedListInsert = ' View Silenced List Insert',
    ViewSilencedListImport = ' View Silenced List Import',
    ViewConfig = ' View Config',
    ViewManageUsers = ' View Manage users',
    ViewInviteUsers = ' View Invite users',
    ViewWebhooksConfig = ' View Webhooks',
    ViewWebhooksConfigDetails = ' View Webhooks Details',
    ViewApiTokens = ' View API Tokens',
    ViewPricing = ' View Pricing',
    ViewPricingDetails = ' View Pricing Details',
    ViewLogs = ' View Logs',
    ViewCustomerDetails = ' View Customer Details',
    ViewUserDetails = ' View User Details',
    ViewPublic = '',
    AddPhoneToBlocklist = 'Add Phone to Blocklist',
    AddVoiceToBlocklist = 'Add Voice to Blocklist',
    RemovePhoneFromBlocklist = 'Remove Phone from Blocklist',
    RemoveVoiceFromBlocklist = 'Remove Voice from Blocklist',
    BiometricsCardClick = 'Biometrics Card Click',
    ButtonClick = 'Button Click',
    Enrollment = 'Enrollment',
    Authentication = 'Authentication',
    AddVoiceFromVerification = 'Add Voice to Blocklist from Verification',
    FinishFlagAnalysis = 'Finish Flag Analysis',
    FinishFlagAnalysisBatch = 'Finish Flag Analysis Batch',
}

export interface MixpanelEventProperties {
    [key: string]: string | number | boolean | Date | undefined | null | object;
}

export function useMixpanel() {
    const identify = (id: string) => {
        mixpanel.identify(id);
    };

    const track = (event: string, props?: MixpanelEventProperties) => {
        mixpanel.track(event, props);
    };

    const setUserProperties = (props: MixpanelUserProperties) => {
        mixpanel.people.set(props);
    };

    const login = (user: MixpanelUserProperties) => {
        identify(user.id.toString());

        track(MixpanelEvents.Login, {
            userId: user.id,
            companyId: user.companyId,
            companyName: user.companyName,
            environment: user.environment,
        });

        setUserProperties(user);
    };

    const logout = (email: string) => {
        mixpanel.track(MixpanelEvents.Logout, { email });
        mixpanel.reset();
    };

    return {
        identify,
        track,
        setUserProperties,
        login,
        logout,
    };
}
