import { DataResponse } from '@apis/responses/data-response';
import { PagedResponse } from '@apis/responses/paged-response';
import { toCamelCaseObject } from '@helpers/transformations';
import { useMixpanel } from '@hooks/useMixpanel';
import { authAtom, defaultValue } from '@stateAtoms/authAtom';
import userAtom from '@stateAtoms/userAtom';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useNotifications } from 'minds-react-sdk';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseResponse } from '../../apis/responses/base-response';
import { config } from '../../helpers/config';
import { HttpMethod, getParams } from '../../helpers/requests';

const methodNames = ['get', 'post', 'put', 'del'] as const;

type Methods = (typeof methodNames)[number];

const DEFAULT_ERROR_RESPONSE: BaseResponse = {
    success: false,
};

interface CachedResponse<T> {
    expires: number;
    data: T;
}

interface CacheOptions {
    duration: number;
    key: string | unknown[];
    location?: Storage;
}

interface RequestOptions {
    data?: object | FormData;
    method?: HttpMethod;
    signal?: AbortSignal;
    options?: RequestInit;
    cache?: CacheOptions;
    sourceFormat?: 'snake' | 'camel';
}

function getCacheKey(key: string | unknown[], companyId: number | undefined) {
    return JSON.stringify({ key, companyId });
}

function simpleRequest(path: string, options: RequestInit) {
    let { apiUrl } = config;

    const isMindsDigitalRequest = apiUrl.includes('minds.digital');
    const isSandboxRequest = window.location.href.includes('sandbox');
    if (isMindsDigitalRequest && isSandboxRequest)
        apiUrl = apiUrl.replace('https://', 'https://sandbox-');

    return fetch(`${apiUrl}${path}`, {
        ...options,
        headers: {
            ...options.headers,
            ...(options.body instanceof FormData
                ? {}
                : { 'Content-Type': 'application/json' }),
        },
        signal: options.signal,
        credentials: 'include',
    });
}

function getBody(isGet: boolean, data?: object | FormData) {
    if (isGet || !data) {
        return undefined;
    }
    if (data instanceof FormData) {
        return data;
    }
    return JSON.stringify(data);
}

export default function useRequest(prefix: string) {
    const logout = useLogout();
    const notification = useNotifications();
    const { t } = useTranslation('common');
    const user = useAtomValue(userAtom);

    async function request<T, TWapper extends BaseResponse = DataResponse<T>>(
        endpoint: string | number,
        options: RequestOptions = {},
    ): Promise<TWapper> {
        try {
            if (options.cache) {
                const { key, location = sessionStorage } = options.cache;
                const cached = location.getItem(
                    getCacheKey(key, user?.companyId),
                );

                if (cached) {
                    const { expires, data: resp } = JSON.parse(
                        cached,
                    ) as CachedResponse<TWapper>;

                    if (Date.now() < expires) return resp;
                }
            }

            const { data, method = 'GET' } = options;
            const isGet = method === 'GET';

            const queryParams = isGet ? getParams(data) : '';
            const body = getBody(isGet, data);

            const sep = endpoint ? '/' : '';

            const resp = await simpleRequest(
                `${prefix}${sep}${endpoint}${queryParams}`,
                {
                    ...options.options,
                    body,
                    method,
                    signal: options.signal,
                },
            );

            const handled = await tryHandleRequestStatus(resp.status);

            if (handled) {
                try {
                    await resp.text();
                } catch {
                    // try catch cala boca pq é só pra aparecer na aba network
                }

                return { ...DEFAULT_ERROR_RESPONSE, handled } as TWapper;
            }

            if (resp.status === 204) return { success: true } as TWapper;

            const responseBody = (await resp.json()) as TWapper;
            const responseData =
                options.sourceFormat === 'camel'
                    ? responseBody
                    : (toCamelCaseObject(responseBody) as TWapper);

            if (options.cache && responseData.success) {
                const {
                    duration,
                    key,
                    location = sessionStorage,
                } = options.cache;
                const expires = Date.now() + duration;

                location.setItem(
                    getCacheKey(key, user?.companyId),
                    JSON.stringify({ expires, data: responseData }),
                );
            }

            return responseData;
        } catch (e) {
            if (config.dev) console.error(e);

            const isAbort =
                e instanceof DOMException && e.name === 'AbortError';

            return {
                ...DEFAULT_ERROR_RESPONSE,
                status: isAbort ? 'aborted' : undefined,
                handled: isAbort,
            } as TWapper;
        }
    }

    async function tryHandleRequestStatus(status: number) {
        if (status === 401) {
            await logout();
            return true;
        }

        if (status === 403) {
            notification.addPush({
                title: t('action_not_allowed_title'),
                content: t('action_not_allowed_message'),
                variant: 'warning',
            });
            return true;
        }

        if ([404, 405].includes(status)) {
            notification.addPush({
                title: t('action_not_found_title'),
                content: t('action_not_found_message'),
                variant: 'error',
            });
            return true;
        }

        if ([429].includes(status)) {
            notification.addPush({
                title: t('oops_something_went_wrong'),
                content: t('action_to_many_request_message'),
                variant: 'error',
            });
            return true;
        }

        if ([500, 502, 503, 504].includes(status)) {
            notification.addPush({
                title: t('error_message_title'),
                content: t('error_message_text'),
                variant: 'warning',
            });
            return true;
        }

        return false;
    }

    function createRequest(method: Methods) {
        return <T, TWapper extends BaseResponse = DataResponse<T>>(
            endpoint: string,
            data?: object,
            options?: RequestOptions,
        ) =>
            request<T, TWapper>(endpoint, {
                ...(options ?? {}),
                data,
                method:
                    method === 'del'
                        ? 'DELETE'
                        : (method.toUpperCase() as HttpMethod),
            });
    }

    const methods = useMemo(() => {
        const entries = methodNames.map((m) => [m, createRequest(m)]);

        return Object.fromEntries(entries) as Record<
            Methods,
            ReturnType<typeof createRequest>
        >;
    }, [prefix]);

    const getPaged = useCallback(
        async <T>(
            endpoint: string,
            data?: object,
            options?: RequestOptions,
        ) => {
            const result = await methods.get<T, PagedResponse<T>>(
                endpoint,
                data,
                options,
            );

            return result;
        },
        [prefix],
    );

    function invalidateCacheKey(key: string | unknown[]) {
        sessionStorage.removeItem(getCacheKey(key, user?.companyId));
    }

    return { request, ...methods, getPaged, invalidateCacheKey };
}

export function useLogout() {
    const [user, setUser] = useAtom(userAtom);
    const setAuth = useSetAtom(authAtom);
    const mixpanel = useMixpanel();

    async function logout() {
        mixpanel.logout(user?.email ?? '');
        setUser(RESET);
        setAuth(defaultValue);

        await simpleRequest('auth/logout', { method: 'POST' });
    }

    return logout;
}
