import { color } from '@helpers/constants';
import { toToken } from 'minds-react-sdk';
import { HTMLAttributes, PropsWithChildren } from 'react';
import { TypographyProps, getFontSize } from './common';

type Props = TypographyProps & HTMLAttributes<HTMLHeadingElement>;

export default function H1({
    variant = 'Sora',
    size = 'md',
    bold,
    m: margin,
    ...props
}: PropsWithChildren<Props>) {
    return (
        <h1
            {...props}
            style={{
                margin,
                fontFamily: variant,
                fontWeight: bold ? 600 : 400,
                fontSize: getFontSize(size, 0.8),
                ...(props.style ?? {}),
                color:
                    toToken(props.color) ??
                    props.style?.color ??
                    (bold ? color('bold-text') : color('dark-text')),
            }}
        >
            {props.children}
        </h1>
    );
}
