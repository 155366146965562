import { config } from '@helpers/config';
import { createRoute, ROUTES } from '@helpers/routeDefinitions';
import { useUserService } from '@hooks/useUser/useUser';
import MainLayout from '@layouts/main-layout/main-layout';
import ErrorBoundary from '@pages/public/error-boundary';
import { AbsoluteCenteredLoading } from 'minds-react-sdk';
import React, { Suspense, useEffect } from 'react';
import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
} from 'react-router-dom';

const LoginLayout = React.lazy(() => import('@layouts/login-layout'));
const AuthenticatedLayout = React.lazy(
    () => import('@layouts/authenticated-layout'),
);

const permissionRoutes = [
    {
        path: '/',
        errorElement: <ErrorBoundary />,
        element: <MainLayout />,
        children: [
            createRoute(ROUTES.PUBLIC),
            createRoute(ROUTES.NOT_FOUND),
            {
                element: <LoginLayout />,
                children: [
                    createRoute(ROUTES.LOGIN),
                    createRoute(ROUTES.FORGOT_PASSWORD),
                    createRoute(ROUTES.RESET_PASSWORD),
                    createRoute(ROUTES.AZURE_LOGIN),
                ],
            },
            {
                element: <AuthenticatedLayout />,
                children: [
                    createRoute(ROUTES.TRIAL),
                    createRoute(ROUTES.DASHBOARD),
                    createRoute(ROUTES.SEARCH),
                    createRoute(ROUTES.FLAGS),
                    createRoute(ROUTES.CERTIFIED),
                    createRoute(ROUTES.BLOCKLIST),
                    createRoute(ROUTES.SILENCED_LIST),
                    createRoute(ROUTES.CONFIG),
                    createRoute(ROUTES.EDIT_PROFILE),
                    createRoute(ROUTES.WATCHLISTS),
                ],
            },
            {
                path: '*',
                element: <Navigate replace to={ROUTES.NOT_FOUND.path} />,
            },
        ],
    },
];

const router = createBrowserRouter(permissionRoutes, {
    basename: config.baseUrl,
});

export default function WebportalRoutes() {
    return (
        <>
            <UserLoggedInProvider />

            <Suspense fallback={<AbsoluteCenteredLoading size="4x" />}>
                <RouterProvider
                    router={router}
                    fallbackElement={<AbsoluteCenteredLoading size="4x" />}
                />
            </Suspense>
        </>
    );
}

function UserLoggedInProvider() {
    const userService = useUserService();

    useEffect(() => {
        void userService.ensurePicturesLoaded();
    }, []);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
}
