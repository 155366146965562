/* eslint-disable import/no-duplicates */
import { setDefaultOptions } from 'date-fns';
import i18n, { t as baseT } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';
import { AsYouType } from 'libphonenumber-js';
import { initReactI18next } from 'react-i18next';
import common from '../../public/locales/pt/common.json';
import { config } from './config';
import { RecommendedActions, RequestSources } from './flagsConstants';
import { typedToLower } from './string';
import { configureYup } from './yup';

export type Route = keyof (typeof common)['routes'];
export type Translator = (key: string, options?: object) => string;
export type SupportedLanguages = (typeof supportedLngs)[number];

const localeMapping = {
    pt: () => import('date-fns/locale/pt-BR').then((x) => x.ptBR),
    en: () => import('date-fns/locale/en-US').then((x) => x.enUS),
    es: () => import('date-fns/locale/es').then((x) => x.es),
};

export const supportedLngs = ['pt', 'en', 'es'] as const;

export function getLanguageLocalName(lng: SupportedLanguages) {
    switch (lng) {
        case 'pt':
            return 'Português';
        case 'en':
            return 'English';
        case 'es':
            return 'Español';
        default:
            return 'Desconhecido';
    }
}

function getLocale(lng: string) {
    const key = lng in localeMapping ? lng : supportedLngs[0];
    const importLocale = localeMapping[key as keyof typeof localeMapping];

    importLocale()
        .then((imp) => {
            setDefaultOptions({ locale: imp });
            configureYup();
        })
        .catch(() => {
            if (config.dev) console.error('Failed to load locale');
        });
}

export function format(value: string, formatType?: string) {
    if (formatType === 'capitalize')
        return `${value.substring(0, 1).toUpperCase()}${value.substring(1)}`;
    return value;
}

export default async function configureI18n() {
    const instance = i18n
        .use(LanguageDetector)
        .use(initReactI18next)
        .use(HttpBackend);

    if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/naming-convention, import/no-extraneous-dependencies, @typescript-eslint/ban-ts-comment, import/no-unresolved
        const { HMRPlugin } = await import('i18next-hmr/plugin');

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        instance.use(new HMRPlugin({ vite: { client: true } }));
    }

    instance.on('languageChanged', (lng: (typeof supportedLngs)[number]) => {
        void getLocale(lng);
    });

    await instance.init<HttpBackendOptions>({
        supportedLngs,
        fallbackLng: supportedLngs[0],
        debug: config.dev,
        load: 'languageOnly',
        defaultNS: 'common',
        ns: 'common',
        preload: false,
        interpolation: {
            escapeValue: false,
            format,
        },
        backend: {
            loadPath: `${config.baseUrl}/locales/{{lng}}/{{ns}}.json`,
            customHeaders: {
                'Cache-Control': 'no-cache',
            },
        },
    });
}

export function translateRouteName(path: Route, t: Translator): string {
    return t(`routes.${path}`, { defaultValue: path });
}

export function getServerErrorNotification() {
    return {
        title: baseT('common:server_error_title'),
        content: baseT('common:error_message_text'),
        variant: 'warning' as const,
    };
}

export function getGenericErrorNotification() {
    return {
        title: baseT('common:oops_something_went_wrong'),
        content: baseT('common:please_try_again'),
        variant: 'error' as const,
    };
}

export function translateRequestSource(t: Translator, source: RequestSources) {
    const key = `common:${typedToLower(source) ?? 'source_unknown'}` as const;

    return i18n.exists(key) ? t(key) : source;
}

export function translateRecommendedAction(
    t: Translator,
    source?: RecommendedActions,
) {
    if (!source) return '';

    const key = source === RecommendedActions.accept ? 'authenticate' : source;

    return t(`common:${key}`);
}

export function getIsoCountryCode(number?: number | string) {
    if (number === 1) return 'US';

    const formatter = new AsYouType();
    formatter.input(`+${number ?? 55}`);
    return formatter.country;
}
