export function isURL(input: string) {
    // eslint-disable-next-line no-useless-escape
    return /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/.test(input);
}

export function isHttpOrHttpsURL(input: string) {
    return /^https?:\/\/.*/.test(input) && isURL(input);
}

export function maskEmail(email: string): string {
    const [username, domain] = email.split('@');
    const numToReplace = Math.min(username.length, 5);
    const indices = new Set<number>();

    while (indices.size < numToReplace) {
        indices.add(Math.floor(Math.random() * username.length));
    }

    const maskedUsername = Array.from(username, (char, index) =>
        indices.has(index) ? '*' : char,
    ).join('');

    return `${maskedUsername}@${domain}`;
}

export function typedToLower<T extends string>(value: T): Lowercase<T> {
    return value?.toLowerCase() as Lowercase<T>;
}
