import { ColorSelector } from 'minds-react-sdk';

export type Sizes =
    | '4xs'
    | '3xs'
    | '2xs'
    | '1xs'
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl';

export interface TypographyProps {
    variant?: 'Inter' | 'Sora';
    bold?: boolean;
    size?: Sizes;
    m?: string;
    textDecoration?: 'none' | 'underline';
    italic?: boolean;
    color?: ColorSelector;
}

export function getBaselineFontSize(size: Sizes) {
    switch (size) {
        case '4xs':
            return 0.25;
        case '3xs':
            return 0.375;
        case '2xs':
            return 0.5;
        case '1xs':
            return 0.625;
        case 'xs':
            return 0.75;
        case 'sm':
            return 0.875;
        case 'md':
            return 1;
        case 'lg':
            return 1.125;
        case 'xl':
            return 1.25;
        case '2xl':
            return 1.5;
        case '3xl':
            return 1.875;
        default:
            return 1;
    }
}

export function getFontSize(size: Sizes, offset = 0) {
    const baseline = getBaselineFontSize(size);

    return `${baseline + offset}rem`;
}
