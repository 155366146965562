/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Backdrop } from '@components/atoms/backdrop';
import H1 from '@components/atoms/typography/H1';
import { Icon, Separator, c } from 'minds-react-sdk';
import { ReactNode } from 'react';
import styles from './modal.module.css';

interface Props {
    children: ReactNode;
    open?: boolean;
    title?: ReactNode;
    onClose: () => void;
    className?: string;
    backdropClassName?: string;
}

export default function Modal({
    children,
    open = true,
    onClose,
    title,
    className,
    backdropClassName,
}: Props) {
    if (!open) return null;

    return (
        <Backdrop onClick={onClose} className={backdropClassName}>
            <div
                className={c(styles.modal, className)}
                onClick={(e) => e.stopPropagation()}
                role="dialog"
            >
                <div className="flex justify-between items-center mt-4 mb-4">
                    <H1 className="m-0 mr-15">{title}</H1>

                    <Icon
                        role="img"
                        name="i-ph-x-bold"
                        className="p-2 hover:cursor-pointer"
                        onClick={onClose}
                    />
                </div>

                <Separator />
                {children}
            </div>
        </Backdrop>
    );
}

Modal.Footer = function ModalFooter({
    children,
    separator = true,
}: {
    children: ReactNode;
    separator?: boolean;
}) {
    return (
        <>
            {separator ? <Separator /> : null}
            <div className="flex justify-end gap-4">{children}</div>
        </>
    );
};

Modal.Content = function ModalContent({ children }: { children: ReactNode }) {
    return <div className="flex flex-col gap-4">{children}</div>;
};
