import { preferencesAtom } from '@stateAtoms/preferencesAtom';
import { useAtomValue } from 'jotai';
import { c, NotificationsProvider } from 'minds-react-sdk';
import { PropsWithChildren } from 'react';
import styles from './notifications-provider-container.module.scss';

export default function NotificationsProviderContainer({
    children,
}: PropsWithChildren) {
    const { sidebarOpen } = useAtomValue(preferencesAtom);

    return (
        <div className="z-[999999999999999] relative">
            <NotificationsProvider
                snackbarContainerClass={c(
                    sidebarOpen ? styles.sidebarOpened : styles.sidebarClosed,
                    styles.snackbar,
                )}
            >
                {children}
            </NotificationsProvider>
        </div>
    );
}
