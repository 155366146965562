import { InferType, mixed, object, string } from 'yup';

export const createZendeskTicketSchema = object().shape({
    name: string().required(),
    email: string().required().email(),
    subject: string().required(),
    description: string().required(),
    file: mixed().optional(),
});

export type CreateZendeskTicket = InferType<typeof createZendeskTicketSchema>;
