/* eslint-disable @typescript-eslint/naming-convention */
export enum RecommendedActions {
    acceptWithRisk = 'accept_with_risk',
    accept = 'accept',
    reject = 'reject',
}

export enum Actions {
    verification = 'verification',
    enrollment = 'enrollment',
}

export enum RequestSources {
    trialWeb = 'TRIAL_WEB',
    api = 'API',
    sdkAndroid = 'SDK_ANDROID',
    sdkIos = 'SDK_IOS',
    ura = 'URA',
    chatbot = 'CHATBOT',
}

export enum FlagTypes {
    phoneBlacklist = 'phone_blacklist',
    speakerBlacklist = 'speaker_blacklist',
    graph = 'graph',
    quarantinePhoneBlocklist = 'quarantine_phone_blocklist',
    quarantineSpeakerBlocklist = 'quarantine_speaker_blocklist',
    voiceDifferent = 'voice_different',
    spoofReplayAttack = 'spoof_replay_attack',
    spoofDeepFake = 'spoof_deep_fake',
    sentenceMismatch = 'sentence_mismatch',
    suspiciousPhoneNumber = 'suspicious_phone_number',
}

export function normalizeFlagType(type: FlagTypes) {
    return type === FlagTypes.graph ? 'suspicious_behaviour' : type;
}

export function getFlagStatusText(hasFlagResult: boolean, isFraud: boolean) {
    if (!hasFlagResult) return 'waiting_analysis' as const;

    return isFraud ? 'confirmed_fraud' : 'no_fraud_found';
}

export enum DocumentTypes {
    cpf = 'cpf',
    generic = 'generic',
    ssn = 'ssn',
}

export enum FlagResults {
    pending = 'waitAnalystic',
    fraud = 'confirmedFlags',
    notFraud = 'noIndexFlags',
}

export enum Reasons {
    voiceMatch = 'voice_match',
    voiceDifferent = 'voice_different',
    confidenceLow = 'confidence_low',
    voiceMatchConfidenceLow = 'voice_match_confidence_low',
    phoneFlag = 'phone_flag',
    voiceFlag = 'voice_flag',
    errorAntiFraud = 'error_anti_fraud',
    spoof = 'spoof',
    errorAntispoofing = 'error_antispoofing',
    enrollmentSuccess = 'enrollment_success',
    silencedPhoneFlag = 'silenced_phone_flag',
    silencedVoiceFlag = 'silenced_voice_flag',
    suspiciousBehaviourFlag = 'suspicious_behaviour_flag',
    quarantinePhoneFlag = 'quarantine_phone_flag',
    quarantineVoiceFlag = 'quarantine_voice_flag',
    sentenceMismatch = 'sentence_mismatch',
    errorSentenceMatch = 'error_sentence_match',
    silencedSuspiciousBehaviourFlag = 'silenced_suspicious_behaviour_flag',
}

export const reasonsWeightMap: Record<Reasons, number> = {
    phone_flag: 3.6,
    voice_flag: 3.5,
    suspicious_behaviour_flag: 3.4,
    quarantine_phone_flag: 3.3,
    quarantine_voice_flag: 3.2,
    silenced_suspicious_behaviour_flag: 3.15,
    silenced_phone_flag: 3.1,
    silenced_voice_flag: 3,
    spoof: 2.2,
    sentence_mismatch: 2.1,
    voice_different: 2,
    voice_match: 1.3,
    enrollment_success: 1.2,
    confidence_low: 1.1,
    voice_match_confidence_low: 1.1,
    error_antispoofing: 1,
    error_sentence_match: 1,
    error_anti_fraud: 1,
};

export const orderedReasonsWeightMap = Object.entries(reasonsWeightMap)
    .sort((a, b) => b[1] - a[1])
    .map((x) => x[0] as Reasons);

export enum Confidence {
    high = 'high',
    medium = 'medium',
    low = 'low',
}

export enum MatchResults {
    match = 'match',
    different = 'different',
}

export enum RequestStatus {
    alreadyEnrolled = 'already_enrolled',
    alreadyRan = 'already_ran',
    error = 'error',
    pending = 'pending',
    processing = 'processing',
    ok = 'ok',
    invalidLength = 'invalid_length',
    audioTooLarge = 'audio_too_large',
    customerNotEnrolled = 'customer_not_enrolled',
    customerAlreadyEnrolledAndCertified = 'customer_already_enrolled_and_certified',
    customerNotFoundToPerformVerification = 'customer_not_found_to_perform_verification',
    internalRequest = 'internal_request',
    activeEnrollmentFound = 'active_enrollment_found',
    invalidDocumentType = 'invalperson_idument_type',
    invalidDocument = 'invalperson_idument',
    audioAlreadyUsed = 'audio_already_used',
    invalidPhoneNumber = 'invalid_phone_number',
}
