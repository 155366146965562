import { addYears, endOfDay, format, startOfDay } from 'date-fns';

export const defaultTo = Date.now();
export const defaultFrom = addYears(defaultTo, -1);

export const defaultDateFilter = () => ({
    id: 'createdAt',
    value: {
        dateFrom: startOfDay(Date.now()),
        dateTo: endOfDay(Date.now()),
    },
});

export interface DateRange {
    from?: Date;
    to?: Date;
}

export function formatDateHour(date: Date | string | undefined) {
    if (!date) return '';

    const isDate =
        date instanceof Date || (typeof date === 'object' && 'd' in date);

    const normalized = isDate ? date : new Date(date);
    return format(normalized, 'P - p');
}

export function formatDate(date: Date) {
    return format(date, 'P');
}

export function isValid(date?: Date | string): date is Date | string {
    // eslint-disable-next-line no-restricted-globals
    return !!(date && date instanceof Date && !isNaN(date.valueOf()));
}
