import { atomWithStorage } from 'jotai/utils';

interface AuthAtom {
    isLoggedIn: boolean;
    rememberMe: boolean;
}

const authTokenKey = '__auth_webportal';

const defaultValue: AuthAtom = {
    isLoggedIn: false,
    rememberMe: false,
};

const initialValue = () => {
    const item = localStorage.getItem(authTokenKey);
    return item ? (JSON.parse(item) as AuthAtom) : defaultValue;
};

const authAtom = atomWithStorage<AuthAtom>(authTokenKey, initialValue());

export type { AuthAtom };
export { authTokenKey, defaultValue, authAtom };
