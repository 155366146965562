import { FeatureKeys, UserProfiles } from '@apis/common';
// eslint-disable-next-line import/no-cycle
import ProtectedRoute from '@components/molecules/protected-route';
import { MixpanelEvents } from '@hooks/useMixpanel';
import useTrialGuard from '@pages/main/trial/useGuard';
import { Icon, IconName } from 'minds-react-sdk';
import React, { ComponentProps, ReactNode } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import {
    BLOCKLIST_PATH,
    BLOCKLIST_PHONES_PATH,
    BLOCKLIST_VOICES_PATH,
    CONFIG_PATH,
    DASHBOARD_PATH,
    FLAGS_FINISH_ANALYSIS_PATH,
    INSERT_PATH,
    INVITE_PATH,
    LOGIN_PATH,
    NOT_FOUND_PATH,
    SEARCH_CLIENT_PATH,
    SEARCH_PATH,
    SEARCH_PHONE_PATH,
} from './constants';

export interface BaseRouteDefinition {
    icon?: IconName;
    iconProps?: ComponentProps<typeof Icon>;
    path?: string;
    element?: ReactNode;
    showOnSidebar?: boolean;
    indexRoute?: ReactNode;
    profilePermission?: UserProfiles[];
    featurePermission?: FeatureKeys;
    mixpanelName?: MixpanelEvents;
    useGuard?: () => readonly [boolean, boolean];
}

export type RouteDefinition =
    | BaseRouteDefinition
    | { [key: string]: RouteDefinition };

const NotFoundPage = React.lazy(() => import('@pages/public/not-found'));

const LoginPage = React.lazy(() => import('@pages/public/login'));
const AzureAdLoginPage = React.lazy(
    () => import('@pages/public/azure-ad-login'),
);
const ForgotPasswordPage = React.lazy(
    () => import('@pages/public/forgot-password'),
);
const ResetPasswordPage = React.lazy(
    () => import('@pages/account/reset-password'),
);

const ConfirmAccountPage = React.lazy(
    () => import('@pages/account/confirm-account'),
);
const EditProfilePage = React.lazy(() => import('@pages/account/edit-profile'));

const TrialPage = React.lazy(() => import('@pages/main/trial'));
const DashboardPage = React.lazy(() => import('@pages/main/dashboard'));
const FlagsPage = React.lazy(() => import('@pages/main/flags'));
const FlagDetailsPage = React.lazy(
    () => import('@pages/main/flags/flag-details'),
);
const FlagFinishAnalysisPage = React.lazy(
    () => import('@pages/main/flags/finish-analysis/v1'),
);
const BlockListPhonesPage = React.lazy(
    () => import('@pages/main/blocklist/phones'),
);
const BlockListVoicesPage = React.lazy(
    () => import('@pages/main/blocklist/voices'),
);
const InsertPhonePage = React.lazy(
    () => import('@pages/main/blocklist/phones/insertPhone/page/insertPhone'),
);
const InsertVoicePage = React.lazy(
    () => import('@pages/main/blocklist/voices/insertVoice/page/insertVoice'),
);

const SearchClientPage = React.lazy(
    () => import('@pages/main/search/client-search'),
);
const ClientSearchHistoryPage = React.lazy(
    () => import('@pages/main/search/client-search/history'),
);
const PhoneSearchPage = React.lazy(
    () => import('@pages/main/search/phone-search'),
);
const OperationSearchPage = React.lazy(
    () => import('@pages/main/search/operation-search'),
);

const SilencedListPage = React.lazy(() => import('@pages/main/silenced-list'));
const SilencedListInsertPage = React.lazy(
    () => import('@pages/main/silenced-list/insert'),
);
const SilencedListImportPage = React.lazy(
    () => import('@pages/main/silenced-list/import'),
);

const PricingPage = React.lazy(() => import('@pages/config/pricing'));
const PricingDetailsPage = React.lazy(
    () => import('@pages/config/pricing/pricing-details'),
);

const CompanyWebhooksPage = React.lazy(
    () => import('@pages/config/company-webhooks'),
);
const CompanyWebhooksDetailsPage = React.lazy(
    () => import('@pages/config/company-webhooks/company-webhook-details'),
);

const CertifiedEnrollmentPage = React.lazy(
    () => import('@pages/main/certified-enrollment'),
);

const CertifiedEnrollmentImportInsertionPage = React.lazy(
    () => import('@pages/main/certified-enrollment/import/insertion'),
);

const CertifiedEnrollmentImportRemovalPage = React.lazy(
    () => import('@pages/main/certified-enrollment/import/removal'),
);

const ApiTokensPage = React.lazy(() => import('@pages/config/api-tokens'));
const InviteUsersPage = React.lazy(() => import('@pages/config/invite-users'));
const ManageUsersPage = React.lazy(() => import('@pages/config/manage-user'));
const LogsPage = React.lazy(() => import('@pages/config/logs-page'));

const WatchlistsPage = React.lazy(() => import('@pages/main/watchlists'));
const WatchlistsDetailsPage = React.lazy(
    () => import('@pages/main/watchlists/watchlists-details'),
);

const managerPermissions = [UserProfiles.Master, UserProfiles.Admin];

export const PROTECTED_ROUTES = {
    TRIAL: {
        icon: 'i-ph-device-mobile-speaker-bold',
        iconProps: {
            rotate: 180,
        } as BaseRouteDefinition['iconProps'],
        path: '/trial',
        element: <TrialPage />,
        showOnSidebar: true,
        mixpanelName: MixpanelEvents.ViewTrial,
        useGuard: useTrialGuard,
    },
    DASHBOARD: {
        icon: 'i-ph-chart-bar-bold',
        path: DASHBOARD_PATH,
        element: <DashboardPage />,
        showOnSidebar: true,
        mixpanelName: MixpanelEvents.ViewDashboard,
    },
    SEARCH: {
        icon: 'i-mds-user-waves-bold',
        path: SEARCH_PATH,
        indexRoute: <OperationSearchPage />,
        showOnSidebar: true,
        mixpanelName: MixpanelEvents.ViewOperationsSearch,
        CLIENT_SEARCH: {
            path: SEARCH_CLIENT_PATH + '/:cpf',
            indexRoute: <SearchClientPage />,
            mixpanelName: MixpanelEvents.ViewClientSearch,
            HISTORY: {
                path: 'history',
                element: <ClientSearchHistoryPage />,
                mixpanelName: MixpanelEvents.ViewClientSearchHistory,
            },
        },
        PHONE_SEARCH: {
            path: SEARCH_PHONE_PATH + '/:phoneNumber',
            element: <PhoneSearchPage />,
            mixpanelName: MixpanelEvents.ViewPhoneSearch,
        },
    },
    FLAGS: {
        icon: 'i-ph-warning-bold',
        path: '/flags',
        indexRoute: <FlagsPage />,
        showOnSidebar: true,
        mixpanelName: MixpanelEvents.ViewAlertList,
        DETAILS: {
            path: 'details/:id',
            element: <FlagDetailsPage />,
            mixpanelName: MixpanelEvents.ViewAlertDetails,
        },
        FINISH_ANALYSIS: {
            path: `${FLAGS_FINISH_ANALYSIS_PATH}/:id`,
            element: <FlagFinishAnalysisPage />,
            mixpanelName: MixpanelEvents.ViewFlagsFinishAnalysis,
        },
    },
    CERTIFIED: {
        icon: 'i-ph-shield-check-bold',
        path: '/certified',
        indexRoute: <CertifiedEnrollmentPage />,
        showOnSidebar: true,
        mixpanelName: MixpanelEvents.ViewCertifiedBiometrics,
        IMPORT: {
            path: 'import/',
            IMPORT_INSERTION: {
                path: 'insertion',
                element: <CertifiedEnrollmentImportInsertionPage />,
                mixpanelName:
                    MixpanelEvents.ViewCertifiedBiometricsImportInsert,
            },
            IMPORT_REMOVAL: {
                path: 'removal',
                element: <CertifiedEnrollmentImportRemovalPage />,
                mixpanelName:
                    MixpanelEvents.ViewCertifiedBiometricsImportRemoval,
            },
        },
    },
    BLOCKLIST: {
        icon: 'i-ph-user-bold',
        path: BLOCKLIST_PATH,
        indexRoute: <Navigate replace to="phones" />,
        featurePermission: FeatureKeys.Blacklist,
        showOnSidebar: true,
        mixpanelName: MixpanelEvents.ViewBlocklist,
        PHONES: {
            path: BLOCKLIST_PHONES_PATH,
            indexRoute: <BlockListPhonesPage />,
            mixpanelName: MixpanelEvents.ViewBlocklistPhones,
            INSERT: {
                path: INSERT_PATH,
                element: <InsertPhonePage />,
                mixpanelName: MixpanelEvents.ViewInsertBlocklistPhones,
            },
            showOnSidebar: true,
            icon: 'i-ph-phone-bold',
        },
        VOICES: {
            path: BLOCKLIST_VOICES_PATH,
            indexRoute: <BlockListVoicesPage />,
            mixpanelName: MixpanelEvents.ViewBlocklistVoices,
            INSERT: {
                path: INSERT_PATH,
                element: <InsertVoicePage />,
                mixpanelName: MixpanelEvents.ViewInsertBlocklistVoices,
            },
            showOnSidebar: true,
            icon: 'i-ph-text-align-center-bold',
            iconProps: {
                rotate: 180,
            } as BaseRouteDefinition['iconProps'],
        },
    },
    WATCHLISTS: {
        icon: 'i-ph-user-bold',
        path: '/watchlists',
        indexRoute: <WatchlistsPage />,
        showOnSidebar: true,
        DETAILS: {
            path: 'details/:id',
            element: <WatchlistsDetailsPage />,
        },
    },
    SILENCED_LIST: {
        icon: 'i-ph-speaker-x-bold',
        path: '/silenced-list',
        indexRoute: <SilencedListPage />,
        featurePermission: FeatureKeys.Whitelist,
        showOnSidebar: true,
        mixpanelName: MixpanelEvents.ViewSilencedList,
        INSERT: {
            path: INSERT_PATH,
            element: <SilencedListInsertPage />,
            mixpanelName: MixpanelEvents.ViewSilencedListInsert,
        },
        IMPORT: {
            path: 'import',
            element: <SilencedListImportPage />,
            mixpanelName: MixpanelEvents.ViewSilencedListImport,
        },
    },
    CONFIG: {
        path: CONFIG_PATH,
        icon: 'i-ph-gear-bold',
        showOnSidebar: true,
        indexRoute: <Navigate replace to="manage-users" />,
        profilePermission: managerPermissions,
        mixpanelName: MixpanelEvents.ViewConfig,
        MANAGE_USERS: {
            icon: 'i-ph-users-bold',
            path: 'manage-users',
            element: <ManageUsersPage />,
            profilePermission: managerPermissions,
            showOnSidebar: true,
            mixpanelName: MixpanelEvents.ViewManageUsers,
        },
        INVITE_USERS: {
            icon: 'i-ph-user-plus-bold',
            path: INVITE_PATH,
            element: <InviteUsersPage />,
            profilePermission: managerPermissions,
            showOnSidebar: true,
            mixpanelName: MixpanelEvents.ViewInviteUsers,
        },
        COMPANY_WEBHOOKS: {
            icon: 'i-ph-sliders-horizontal-bold',
            path: 'company-webhooks',
            indexRoute: <CompanyWebhooksPage />,
            mixpanelName: MixpanelEvents.ViewWebhooksConfig,
            DETAILS: {
                path: 'details/:companyWebhookId',
                element: <CompanyWebhooksDetailsPage />,
                mixpanelName: MixpanelEvents.ViewWebhooksConfigDetails,
            },
            profilePermission: [UserProfiles.Master, UserProfiles.Admin],
            showOnSidebar: true,
        },
        API_TOKENS: {
            icon: 'i-ph-key-bold',
            path: 'api-tokens',
            element: <ApiTokensPage />,
            profilePermission: [UserProfiles.Master, UserProfiles.Admin],
            showOnSidebar: true,
            mixpanelName: MixpanelEvents.ViewApiTokens,
        },
        PRICING: {
            icon: 'i-ph-money-bold',
            path: 'pricing',
            indexRoute: <PricingPage />,
            mixpanelName: MixpanelEvents.ViewPricing,
            DETAILS: {
                path: 'details/:month',
                element: <PricingDetailsPage />,
                mixpanelName: MixpanelEvents.ViewPricingDetails,
            },
            profilePermission: [UserProfiles.Master, UserProfiles.Admin],
            showOnSidebar: true,
        },
        LOGS: {
            icon: 'i-ph-file-bold',
            path: 'logs',
            element: <LogsPage />,
            mixpanelName: MixpanelEvents.ViewLogs,
            profilePermission: [UserProfiles.Master, UserProfiles.Admin],
            showOnSidebar: true,
        },
    },
    EDIT_PROFILE: {
        path: '/edit-profile',
        element: <EditProfilePage />,
        mixpanelName: MixpanelEvents.ViewCustomerDetails,
    },
} satisfies Record<string, RouteDefinition>;

export const ROUTES = {
    LOGIN: {
        path: LOGIN_PATH,
        element: <LoginPage />,
        mixpanelName: MixpanelEvents.ViewLogin,
    },
    FORGOT_PASSWORD: {
        path: '/forgot-password',
        element: <ForgotPasswordPage />,
        mixpanelName: MixpanelEvents.ViewForgotPassword,
    },
    RESET_PASSWORD: {
        path: '/reset-password',
        element: <ResetPasswordPage />,
        mixpanelName: MixpanelEvents.ViewResetPassword,
    },
    PUBLIC: {
        path: '/',
        indexRoute: <Navigate to="/login" />,
        mixpanelName: MixpanelEvents.ViewPublic,
        CONFIRM_ACCOUNT: {
            path: 'confirm-account',
            element: <ConfirmAccountPage />,
            mixpanelName: MixpanelEvents.ViewConfirmAccount,
        },
    },
    AZURE_LOGIN: {
        path: '/azure-login/:companyName',
        element: <AzureAdLoginPage />,
        mixpanelName: MixpanelEvents.ViewAzureLogin,
    },
    NOT_FOUND: {
        path: NOT_FOUND_PATH,
        element: <NotFoundPage />,
        mixpanelName: MixpanelEvents.ViewNotFound,
    },
    ...PROTECTED_ROUTES,
} satisfies Record<string, RouteDefinition>;

export const getChildRoutes = (
    route: RouteDefinition,
    isMenu?: boolean,
): BaseRouteDefinition[] =>
    Object.entries(route)
        .filter(
            ([key, v]) =>
                /^[A-Z]/.test(key) &&
                (!isMenu || (v as BaseRouteDefinition).showOnSidebar),
        )
        .map(([, v]) => v as BaseRouteDefinition);

export const createRoute = (
    definition: BaseRouteDefinition,
    depth = 0,
    parentPath = '',
): RouteObject => {
    const children = getChildRoutes(definition)?.map((c) =>
        createRoute(c, depth + 1, definition.path),
    );

    if (definition.indexRoute) {
        children.push({ index: true, element: definition.indexRoute });
    }

    return {
        element: (
            <ProtectedRoute
                depth={depth}
                definition={definition}
                parentPath={parentPath}
                key={(definition.mixpanelName ?? '') + (definition.path ?? '')}
            />
        ),
        path: definition.path,
        children,
    };
};

export function getDefinitionFromPath(path: string) {
    let route: RouteDefinition = ROUTES;

    const parts = path.toUpperCase().replaceAll('/', ' ').trim().split(' ');

    for (const part of parts) {
        const normalizedPart = part?.replace('-', '_') as keyof RouteDefinition;

        if (route.path?.toString().includes(':') && !(normalizedPart in route))
            continue;

        route = route[normalizedPart] as RouteDefinition;
    }

    return route as BaseRouteDefinition;
}
