import { atom } from 'jotai';
import { ReactNode } from 'react';

interface ConfirmModalProps {
    message: string;
    onConfirm: () => unknown;
    onCancel?: () => unknown;
    confirmText?: string;
    type: 'confirm';
}

type ModalTypes = ConfirmModalProps;

interface GlobalModalProps extends ModalTypes {
    title: ReactNode;
}

const globalModalAtom = atom<GlobalModalProps | null>(null);

export default globalModalAtom;
export type { ConfirmModalProps };
