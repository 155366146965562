export interface Config {
    apiUrl: string;
    zendeskUrl: string;
    env: 'staging' | 'sandbox' | 'production';
    dev: boolean;
    mixpanelToken: string;
    featureToggleUrl: string;
    baseUrl: string;
}

let baseUrl = import.meta.env.BASE_URL;

if (baseUrl.endsWith('/')) {
    baseUrl = baseUrl.slice(0, -1);
}

const remote = {
    apiUrl: 'https://staging-services.minds.digital/webportal-api/',
    featureToggleUrl:
        'https://staging-services.minds.digital/webportal-api/v1.0/',
};

const localWithoutLegacy = {
    apiUrl: 'http://localhost:5000/api/',
    featureToggleUrl: 'http://localhost:5000/api/v1.0/',
};

// eslint-disable-next-line import/no-mutable-exports
let config: Config = {
    ...remote,
    // ...localWithoutLegacy,
    zendeskUrl: '',
    env: 'staging',
    dev: import.meta.env.DEV,
    mixpanelToken: '',
    baseUrl,
};

async function loadConfig() {
    const url = import.meta.env.BASE_URL + 'assets/environment.json';

    const resp = await fetch(url);
    const env = (await resp.json()) as Partial<Config>;

    let ftApiUrl = env.featureToggleUrl || config.featureToggleUrl || '';
    const isSandboxEnv = window.location.href.includes('sandbox');
    if (isSandboxEnv && !ftApiUrl.includes('sandbox-'))
        ftApiUrl = ftApiUrl.replace('https://', 'https://sandbox-');

    config = { ...config, ...env, featureToggleUrl: ftApiUrl };

    return config;
}

export { config, loadConfig };
