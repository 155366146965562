import Modal from '@components/molecules/modal';
import globalModalAtom, {
    ConfirmModalProps,
} from '@stateAtoms/globalModalAtom';
import { store } from '@stateAtoms/store';
import { useAtom, useAtomValue } from 'jotai';
import { Button } from 'minds-react-sdk';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

export default function GlobalModalProvider() {
    const [modal, setModal] = useAtom(globalModalAtom, { store });

    if (!modal) return null;

    function getType() {
        if (modal?.type === 'confirm') return ConfirmModal;
        return Fragment;
    }

    const ModalComponent = getType();

    return (
        <Modal title={modal.title} open onClose={() => setModal(null)}>
            <ModalComponent onClose={() => setModal(null)} />
        </Modal>
    );
}

function ConfirmModal({ onClose }: { onClose: () => void }) {
    const modal = useAtomValue(globalModalAtom, { store }) as ConfirmModalProps;
    const { t } = useTranslation();

    return (
        <>
            <Modal.Content>
                <p>{modal.message}</p>
            </Modal.Content>

            <Modal.Footer>
                <Button
                    variant="outline-dark"
                    className="w-48"
                    content={t('cancel')}
                    onClick={() => {
                        modal.onCancel?.();
                        onClose();
                    }}
                />
                <Button
                    onClick={() => {
                        modal.onConfirm();
                        onClose();
                    }}
                    content={modal.confirmText ?? t('confirm')}
                    className="w-48"
                />
            </Modal.Footer>
        </>
    );
}
