import { FeatureKeys, UserProfiles } from '@apis/common';
import type { Keys } from '@hooks/useFeatures';
import { useEnabledFeatures } from '@hooks/useFeatures';
import userAtom from '@stateAtoms/userAtom';
import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { titleCase } from 'tiny-case';
import { CamelCase } from 'type-fest';

export const showPagesFeatureKeys: Keys[] = [
    'show_menu_dashboard',
    'show_menu_flags',
    'show_menu_certified',
    'show_menu_blocklist',
    'show_menu_search',
    'show_menu_silenced_list',
    'show_menu_manage_users',
    'show_menu_invite_users',
    'show_menu_company_webhooks',
    'show_menu_api_tokens',
    'show_menu_edit_profile',
    'show_menu_permission_blacklist',
    'show_menu_permission_whitelist',
    'show_menu_pricing',
    'show_menu_logs',
    'show_menu_trial',
];

type Permissions =
    | 'show_menu_permission_blacklist'
    | 'show_menu_permission_whitelist';

export default function useUserPermission() {
    const user = useAtomValue(userAtom);
    const [enabledFeatures, loading, get] =
        useEnabledFeatures(showPagesFeatureKeys);

    const profileIsAllowed = useCallback(
        (acceptedProfiles?: UserProfiles[]) =>
            !user ||
            !acceptedProfiles ||
            acceptedProfiles?.includes(user?.profile),
        [user?.profile],
    );

    const userFeatureIsAllowed = useCallback(
        (requiredFeature?: FeatureKeys) =>
            !user ||
            !requiredFeature ||
            !!user.features?.includes(requiredFeature),
        [user?.features],
    );

    const showPage = useCallback(
        (path?: string) => {
            if (!path) return false;

            const feature =
                enabledFeatures?.[
                    `showMenu${titleCase(path)}` as CamelCase<Keys>
                ];

            return feature === undefined || feature;
        },
        [JSON.stringify(enabledFeatures)],
    );

    const isAllowed = useCallback(
        (
            path?: string,
            acceptedProfiles?: UserProfiles[],
            requiredFeature?: FeatureKeys,
        ) =>
            showPage(path) &&
            profileIsAllowed(acceptedProfiles) &&
            userFeatureIsAllowed(requiredFeature),
        [showPage, profileIsAllowed, userFeatureIsAllowed],
    );

    const featuresLoadedAndIsAllowedAsync = useCallback(
        async (
            path?: string,
            acceptedProfiles?: UserProfiles[],
            requiredFeature?: FeatureKeys,
        ) => {
            if (!path) return false;
            await get();

            return isAllowed(path, acceptedProfiles, requiredFeature);
        },
        [isAllowed, JSON.stringify(enabledFeatures)],
    );

    const showPageWithoutPermission = useCallback(
        (permission: FeatureKeys) =>
            enabledFeatures?.[
                `showMenuPermission${titleCase(
                    FeatureKeys[permission] ?? permission,
                )}` as CamelCase<Permissions>
            ],
        [JSON.stringify(enabledFeatures)],
    );

    return useMemo(
        () => ({
            profileIsAllowed,
            userFeatureIsAllowed,
            showPage,
            isAllowed,
            showPageWithoutPermission,
            featuresLoadedAndIsAllowedAsync,
            loading,
        }),
        [
            profileIsAllowed,
            userFeatureIsAllowed,
            showPage,
            isAllowed,
            showPageWithoutPermission,
            featuresLoadedAndIsAllowedAsync,
            loading,
        ],
    );
}
