export enum UserProfiles {
    Master,
    EndUser,
    Admin,
    Service,
    Manager,
}

export enum Status {
    Disabled,
    Enabled,
    Deleted,
}

export enum Environment {
    Production = 1,
    Sandbox,
    Staging,
}

export enum FeatureKeys {
    SpeakerVerification,
    SpeakerIdentification,
    Blacklist,
    Whitelist,
    Onboarding,
    FaceRecognition,
    DocsClassification,
    TextInImage,
    SpeechToText,
    FraudDetection,
    SignatureMatch,
    CompareTwoAudios,
    DriverLicense,
}

export enum AntispoofingTypes {
    ReplayAttack,
    SyntheticVoice,
}

export enum AntispoofingResults {
    Live = 'live',
    Spoof = 'spoof',
    Error = 'error',
}