import { DASHBOARD_PATH, NOT_FOUND_PATH } from '@helpers/constants';
import type { BaseRouteDefinition } from '@helpers/routeDefinitions';
// eslint-disable-next-line import/no-cycle
import useTryNavigate from '@hooks/useTryNavigate';
import useUserPermission from '@hooks/useUserPermission/useUserPermission';
import { useRef, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface Props {
    definition: BaseRouteDefinition;
    depth: number;
    parentPath: string;
}

export default function ProtectedRoute({
    definition,
    depth,
    parentPath,
}: Props) {
    const [, setTryingToRedirect] = useState(false);
    const { isAllowed, showPage, loading } = useUserPermission();
    const tryNavigateAsync = useTryNavigate();
    const tryingToRedirectRef = useRef(false);

    const [shouldShow, loadingGuard] = definition.useGuard?.() ?? [true, false];

    const checkUserPermission =
        depth > 0 &&
        (!!definition.profilePermission || !!definition.featurePermission);

    async function navigateToDashboard() {
        try {
            if (tryingToRedirectRef.current) return;
            tryingToRedirectRef.current = true;

            setTryingToRedirect(true);
            await tryNavigateAsync(DASHBOARD_PATH);
        } finally {
            tryingToRedirectRef.current = false;
            setTryingToRedirect(false);
        }
    }

    if (loading || loadingGuard || tryingToRedirectRef.current) return null;

    if (
        !shouldShow ||
        !showPage(definition.path) ||
        (parentPath && !showPage(parentPath))
    ) {
        void navigateToDashboard();
        return null;
    }

    if (
        checkUserPermission &&
        !isAllowed(
            definition.path,
            definition.profilePermission,
            definition.featurePermission,
        )
    ) {
        return <Navigate replace to={NOT_FOUND_PATH} />;
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{definition.element ?? <Outlet />}</>;
}
