import { toCamelCaseObject } from '@helpers/transformations';
import userAtom from '@stateAtoms/userAtom';
import {
    useEnabledFeatures as useEnabledFeaturesBase,
    useFeatures as useFeatureBase,
} from 'feature_toggle_react_client';
import { useAtomValue } from 'jotai';
import type { CamelCasedPropertiesDeep } from 'type-fest';

export type Keys =
    | 'show_menu_permission_blacklist'
    | 'show_menu_permission_whitelist'
    | 'show_menu_dashboard'
    | 'show_menu_flags'
    | 'show_menu_certified'
    | 'show_menu_blocklist'
    | 'show_menu_search'
    | 'show_menu_silenced_list'
    | 'show_menu_manage_users'
    | 'show_menu_invite_users'
    | 'show_menu_company_webhooks'
    | 'show_menu_api_tokens'
    | 'show_menu_edit_profile'
    | 'show_menu_pricing'
    | 'show_menu_logs'
    | 'show_search_bar'
    | 'flag_details_show_labels'
    | 'new_blocklist_page'
    | 'remove_call_center_box'
    | 'show_menu_trial'
    | 'webportal_finish_flags_v2'
    | 'webportal_graph_vis_v2'
    | 'webportal_flag_insights'
    | 'webportal_new_watchlist';

export default function useFeatures<T extends Keys>(keys: T[]) {
    const user = useAtomValue(userAtom);
    return useFeatureBase(keys, user?.companyId ?? 0);
}

export function useEnabledFeatures<
    TKeys extends Keys,
    T extends ReturnType<typeof useEnabledFeaturesBase<TKeys>> = ReturnType<
        typeof useEnabledFeaturesBase<TKeys>
    >,
>(
    keys: TKeys[],
    options?: Partial<Parameters<typeof useEnabledFeaturesBase<TKeys>>[2]>,
): [Partial<CamelCasedPropertiesDeep<T[0]>>, T[1], T[2]] {
    const user = useAtomValue(userAtom);

    const [values, ...rest] = useEnabledFeaturesBase(
        keys,
        user?.companyId ?? 0,
        {
            ...(options ?? {}),
            requireCompanyId: true,
        },
    ) || [{}];

    const normalizedKeys = toCamelCaseObject(values);

    return [normalizedKeys, ...rest];
}
