import {
    CreateZendeskTicket,
    createZendeskTicketSchema,
} from '@apis/requests/zendesk/create-ticket';
import TextArea from '@components/atoms/inputs/text-area';
import { color } from '@helpers/constants';
import { getGenericErrorNotification } from '@helpers/i18n';
import { isFieldValid } from '@helpers/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useZendesk from '@hooks/requests/useZendesk';
import useUser from '@hooks/useUser';
import { authAtom } from '@stateAtoms/authAtom';
import zendeskPopoverAtom from '@stateAtoms/zendeskAtom';
import { useAtom, useAtomValue } from 'jotai';
import {
    Button,
    Icon,
    Popover,
    Separator,
    TextInput,
    c,
    useNotifications,
} from 'minds-react-sdk';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FileInput from '../file-input/file-input';
import style from './zendesk-popover.module.scss';

const possibleErrors = [
    'zendesk_invalid_file_type',
    'zendesk_attachment_too_big',
    'subject_required',
    'description_required',
    'email_required',
    'first_name_required',
] as const;

interface Props {
    onClose?: () => unknown;
    onSend?: () => unknown;
}

export default function ZendeskPopover({ ...props }: Props) {
    const [zendeskAtom, setZendeskAtom] = useAtom(zendeskPopoverAtom);
    const [isMinimized, setIsMinimized] = useState(false);
    const { t } = useTranslation('common');
    const wrapperRef = useRef(null);
    const auth = useAtomValue(authAtom);
    const user = useUser();
    const api = useZendesk();
    const { addPush } = useNotifications();

    const {
        register,
        setValue,
        reset,
        handleSubmit,
        formState: { errors, dirtyFields, isSubmitted, isSubmitting },
        control,
    } = useForm<CreateZendeskTicket>({
        resolver: yupResolver(createZendeskTicketSchema),
        mode: 'onChange',
    });

    useEffect(() => {
        if (auth.isLoggedIn) {
            setValue('name', user?.name);
            setValue('email', user?.email);
        }
    }, [user, setValue]);

    const closeZendeskPopover = () => {
        setZendeskAtom((z) => ({ ...z, visible: false }));
    };

    function loggedOutFields() {
        return (
            <>
                <TextInput
                    {...register('name')}
                    className={style.zendeskInput}
                    label={t('fullName')}
                    name="name"
                    valid={
                        auth?.isLoggedIn ||
                        isFieldValid(
                            errors.name,
                            dirtyFields.name || isSubmitted,
                        )
                    }
                    id="name"
                />
                <TextInput
                    {...register('email')}
                    name="email"
                    className={style.zendeskInput}
                    label={t('email')}
                    valid={
                        auth?.isLoggedIn ||
                        isFieldValid(
                            errors.email,
                            dirtyFields.email || isSubmitted,
                        )
                    }
                    id="email"
                />
            </>
        );
    }

    const handleExpandPopover = () => {
        setIsMinimized((m) => !m);
    };

    async function handleFormSubmission(data: CreateZendeskTicket) {
        const response = await api.createTicket(data);

        if (!response.success) {
            if (response.errors) {
                const error = Object.values(
                    response.errors,
                )[0][0] as (typeof possibleErrors)[number];

                addPush({
                    title: t('oops_something_went_wrong'),
                    content: possibleErrors.includes(error)
                        ? t(error)
                        : t('please_try_again'),
                    variant: 'error',
                });
                return;
            }

            addPush(getGenericErrorNotification());

            return;
        }

        addPush({
            title: t('success'),
            content: t('ourTeamWillReplySoon'),
            variant: 'success',
        });

        reset();
        closeZendeskPopover();

        if (props.onSend) {
            props.onSend();
            return;
        }

        props.onClose?.();
    }

    return (
        <div>
            <Popover
                targetRef={wrapperRef}
                visible={zendeskAtom.visible}
                wrapperClassName={style.zendeskContainer}
                customCardClass={c(
                    style.zendeskCard,
                    isMinimized && style.minimizedCard,
                )}
                customCardChildrenClass={style.cardChildren}
            >
                <div
                    className={c(
                        style.headerContainer,
                        isMinimized && style.headerContainerMinimized,
                    )}
                    onClick={handleExpandPopover}
                    aria-hidden="true"
                >
                    <h5
                        className={c(
                            style.headerText,
                            isMinimized && style.clickableHeader,
                        )}
                    >
                        {t('readyToHelp')}
                    </h5>
                    <div className={style.minimizeIcon}>
                        <Icon
                            name={isMinimized ? 'i-ph-plus' : 'i-ph-minus'}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleExpandPopover();
                            }}
                            color={color('gray-300')}
                        />
                    </div>
                </div>

                <div className={style.content}>
                    <form
                        className={style.zendeskForm}
                        onSubmit={handleSubmit(handleFormSubmission)}
                    >
                        {!auth.isLoggedIn && loggedOutFields()}
                        <TextInput
                            {...register('subject')}
                            className={style.zendeskInput}
                            label={t('subject')}
                            name="subject"
                            valid={isFieldValid(
                                errors.subject,
                                dirtyFields.subject || isSubmitted,
                            )}
                            id="subject"
                        />
                        <TextArea
                            {...register('description')}
                            className={style.zendeskInput}
                            label={t('description')}
                            name="description"
                            noResize
                            valid={isFieldValid(
                                errors.description,
                                dirtyFields.description || isSubmitted,
                            )}
                            id="description"
                        />
                        <label className={style.label} htmlFor="file">
                            {t('attachment')}
                        </label>
                        <FileInput
                            control={control}
                            name="file"
                            id="file"
                            acceptedTypes={['.jpg', '.jpeg', '.png', '.pdf']}
                            invalid={!!errors.file}
                            removeText={t('remove')}
                        />
                        <Separator />
                        <div className={style.buttonsContainer}>
                            <Button
                                className={c(style.actionButton)}
                                content={t('cancel')}
                                onClick={closeZendeskPopover}
                                variant="outline-dark"
                                id="cancel"
                            />
                            <Button
                                className={c(style.actionButton)}
                                content={t('send')}
                                type="submit"
                                loading={isSubmitting}
                                id="send"
                            />
                        </div>
                    </form>
                </div>
            </Popover>
        </div>
    );
}
