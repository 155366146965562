import { QrCodeRedirectType } from '@apis/requests/trial/trial';
import { AppUrlsResponse } from '@apis/responses/trial/app-urls-response';
import { GetTrialResponse } from '@apis/responses/trial/get-trial-response';
import { config } from '@helpers/config';
import useRequest from '@hooks/useRequest';
import useUser from '@hooks/useUser';

export default function useTrial() {
    const api = useRequest('trial');

    const user = useUser();

    function getAppUrls() {
        return api.get<AppUrlsResponse>('app-urls');
    }

    function getQrCodeUrl(type: QrCodeRedirectType) {
        const baseUrl = config.apiUrl + 'trial/qr-code';
        const params = new URLSearchParams({
            type: Number(type).toString(),
            environment: Number(user.environment).toString(),
            companyId: user.companyId.toString(),
        });

        return `${baseUrl}?${params.toString()}`;
    }

    function getTokenLoginUrl(token: string) {
        const baseUrl = config.apiUrl + 'auth/trial-token-login';
        const params = new URLSearchParams({
            token,
        });

        return `${baseUrl}?${params.toString()}`;
    }

    function getTrial(companyId: number) {
        return api.get<GetTrialResponse>(
            '',
            { companyId },
            {
                cache: {
                    key: `trial-${companyId}`,
                    duration: 1000 * 60 * 15,
                },
            },
        );
    }

    function getLoginToken() {
        return api.get<string>('login-token');
    }

    function getCallcenter() {
        return api.get<string>('callcenter');
    }

    return {
        getAppUrls,
        getQrCodeUrl,
        getTrial,
        getLoginToken,
        getTokenLoginUrl,
        getCallcenter,
    };
}
