import { format as formatFn } from 'date-fns';
import { pascalCase } from 'tiny-case';
import { isValid } from './date';

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

const getParamsFromEntries = (entries: [string, unknown][]): string =>
    entries
        .map(([key, value]) => {
            if (Array.isArray(value)) {
                return getParamsFromEntries(value.map((v) => [key, v]));
            }

            const val =
                key === 'sort'
                    ? pascalCase(value as string)
                    : (value as string);

            return `${key}=${encodeURIComponent(val)}`;
        })
        .join('&');

export function getParams(params?: object) {
    if (!params) return '';

    const str = getParamsFromEntries(
        Object.entries(params).filter(([, value]) => value !== undefined),
    );

    return '?' + str;
}

export const getNavigatorLanguage = () => {
    let lang = '';

    if (window.Intl) {
        lang = Intl.NumberFormat().resolvedOptions().locale;
    } else if (navigator.languages && navigator.languages.length) {
        // eslint-disable-next-line prefer-destructuring
        lang = navigator.languages[0];
    } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        lang =
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            navigator.userLanguage ||
            navigator.language ||
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            navigator.browserLanguage ||
            'pt-BR';
    }

    return lang.includes('-') ? lang : '';
};

type FormattedDates<T extends object> = { [key in keyof T]: string };

export function formatDates<T extends Record<string, Date | undefined>>(
    input: T,
    format: string,
): FormattedDates<T> {
    const entries = Object.entries(input).map(([key, value]) => [
        key,
        isValid(value) ? formatFn(value, format) : undefined,
    ]);

    return Object.fromEntries(entries) as FormattedDates<T>;
}
