import Logo from '@components/atoms/logo';
import Subtitle from '@components/atoms/texts/subtitle';
import Title from '@components/atoms/texts/title';
import { DASHBOARD_PATH, LOGIN_PATH } from '@helpers/constants';
import useReload from '@hooks/useReload';
import { authAtom } from '@stateAtoms/authAtom';
import { useAtomValue } from 'jotai';
import { Button, Icon } from 'minds-react-sdk';
import { useTranslation } from 'react-i18next';
import { Link, useRouteError } from 'react-router-dom';
import errorBoundary from '../../../assets/img/errorBoundary.svg';
import style from './error-boundary.module.scss';

interface ReloadCounter {
    count: number;
    date: number;
}

function getLastReload() {
    return JSON.parse(
        localStorage.getItem('last_reload') ?? '{"count": 0, "date": 0}',
    ) as ReloadCounter;
}

function setLastReload(count: number) {
    localStorage.setItem(
        'last_reload',
        JSON.stringify({
            count,
            date: Date.now(),
        }),
    );
}

const RELOAD_MESSAGES = [
    'dynamically imported module',
    'importing a module script failed',
    'foi bloqueado devido a um tipo mime não permitido',
];

export default function ErrorBoundary({
    maxReloads = 3,
}: {
    maxReloads?: number;
}) {
    const error = useRouteError();
    const reload = useReload();
    const { t } = useTranslation(['errorBoundary', 'common']);
    const auth = useAtomValue(authAtom);

    const message =
        error instanceof Error && 'message' in error
            ? error.message?.toLowerCase()
            : undefined;

    if (message && RELOAD_MESSAGES.some((m) => message.includes(m))) {
        const lastReload = getLastReload();
        const hasPassed3Seconds = lastReload.date < Date.now() - 3000;

        if (!hasPassed3Seconds && lastReload.count >= maxReloads) {
            return null;
        }

        setLastReload(hasPassed3Seconds ? 1 : lastReload.count + 1);
        reload();

        return null;
    }

    return (
        <div className={style.errorBoundary}>
            <Logo />

            <img
                src={errorBoundary}
                alt={t('errorBoundary:img_logo')}
                className={style.errorImg}
            />
            <Title className={style.title}>{t('errorBoundary:title')}</Title>
            <Subtitle className={style.message}>
                {t('errorBoundary:message')}
            </Subtitle>
            <div className={style.buttonContainer}>
                <Link
                    to={auth.isLoggedIn ? DASHBOARD_PATH : LOGIN_PATH}
                    reloadDocument
                >
                    <Button
                        className={style.goBackHome}
                        content={t('errorBoundary:go_back_button')}
                        left={<Icon name="i-ph-house" />}
                    />
                </Link>
                <Button
                    onClick={reload}
                    content={t('common:reload')}
                    variant="outline-dark"
                    left={<Icon name="i-ph-arrows-clockwise" />}
                    className={style.realodButton}
                />
            </div>
        </div>
    );
}
