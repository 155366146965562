import { getRawCssVariable as getRaw, RawColor, token } from 'minds-react-sdk';
import styles from '../styles/colors.module.scss';
import { toCamelCaseObject } from './transformations';

export const color = token;
export const getRawCssColor = getRaw;

interface StyleGuideColors {
    primary: RawColor;
    primaryLight: RawColor;
    primaryLight2: RawColor;
    primaryLight3: RawColor;
    primaryLight4: RawColor;
    primaryLight5: RawColor;
    primaryAccent: RawColor;
    secondary: RawColor;
    secondaryBright: RawColor;
    secondaryLight: RawColor;
    secondaryLight2: RawColor;
    secondaryLight3: RawColor;
    secondaryLight4: RawColor;
    secondaryLight5: RawColor;
    secondaryLight6: RawColor;
    secondaryLight7: RawColor;
    secondaryLight8: RawColor;
    secondaryLight9: RawColor;
    tertiary: RawColor;
    tertiaryLight: RawColor;
    defaultGray: RawColor;
    darkGray: RawColor;
    gray: RawColor;
    red: RawColor;
    errorRed: RawColor;
    bluePrimary: RawColor;
    grayLight: RawColor;
    grayLight1: RawColor;
    grayLight2: RawColor;
    grayLight3: RawColor;
    grayLight4: RawColor;
    grayLight5: RawColor;
    grayLight6: RawColor;
    lightGray: RawColor;
    lightRed1: RawColor;
    lightRed2: RawColor;
    lightRed3: RawColor;
    lightRed4: RawColor;
    lightRed5: RawColor;
    lightRed6: RawColor;
    dangerRed: RawColor;
    lightGreen: RawColor;
    irishGreen: RawColor;
    darkGreen: RawColor;
    dangerYellow: RawColor;
}

export const COLORS = toCamelCaseObject(styles) as unknown as StyleGuideColors;

export const BREAKPOINTS = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
};

export const BOOLEAN_OPTIONS = [
    {
        value: true,
        label: 'Sim',
    },
    {
        value: false,
        label: 'Não',
    },
];
export const TYPES_MASK = {
    CPF: '999.999.999-99',
    CNPJ: '99.999.999/9999-99',
    PHONE: '(99) 99999-9999',
    REAIS: 'R$999.999.999,99',
    NUMBER_WITH_SEPARATORS: '999.999.999.999.999,99',
    PERCENT: '99,99%',
};
export const NOT_FOUND_PATH = '/not-found';
export const LOGIN_PATH = '/login';
export const DASHBOARD_PATH = '/dashboard';

export const BLOCKLIST_PATH = '/blocklist';
export const BLOCKLIST_VOICES_PATH = 'voices';
export const BLOCKLIST_PHONES_PATH = 'phones';
export const INSERT_PATH = 'insert';

export const SEARCH_PATH = '/search';
export const SEARCH_CLIENT_PATH = 'client-search';
export const SEARCH_PHONE_PATH = 'phone-search';
export const SEARCH_BIOMETRICS_AUTHENTICATION_PATH =
    'biometrics-authentication-search';

export const FLAGS_DETAILS_PATH = '/flags/details';
export const FLAGS_FINISH_ANALYSIS_PATH = 'finish-analysis';

export const INVITE_PATH = 'invite-users';
export const CONFIG_PATH = '/config';

export const WATCHLISTS_PATH = '/watchlists';
export const WATCHLISTS_DETAILS_PATH = '/watchlists/details';
