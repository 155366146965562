import { UserClaims } from '@helpers/auth';
import useUsers from '@hooks/requests/useUsers';
import userAtom from '@stateAtoms/userAtom';
import { addDays } from 'date-fns';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';

export const USER_PICTURE_KEY = 'userPictureExpiration';

export default function useUser() {
    const user = useAtomValue(userAtom);

    const companyInfo = user?.companyInfo || { defaultPhoneCountryCode: 55 };

    return {
        ...(user || {}),
        companyInfo: {
            ...companyInfo,
            defaultPhoneCountryCode: companyInfo.defaultPhoneCountryCode ?? 55,
        },
    } as UserClaims;
}

const BMG_COMPANY_ID = 3;

export function useUserService() {
    const setUser = useSetAtom(userAtom);
    const userApi = useUsers();
    const user = useUser();

    const ensurePicturesLoaded = useCallback(async () => {
        const [pictureExp] = getExpirations();
        const now = Date.now();
        let userUpdate: Partial<Pick<UserClaims, 'profilePicture'>> = {};
        if (pictureExp && pictureExp < now) {
            userUpdate = {
                ...userUpdate,
                ...((await setUserProfilePicture()) ?? {}),
            };
        }

        if (Object.keys(userUpdate).length) {
            setUser((u) => (u ? { ...u, userUpdate } : null));
        }
    }, []);

    async function setUserProfilePicture(hasPicture = true) {
        if (!hasPicture) {
            return { profilePictureExpiration: undefined };
        }

        const resp = await userApi.getSignedProfilePicture();

        if (!resp.success) return;

        updateProfilePictureExpiration();

        return { profilePicture: resp.data };
    }

    const updateProfilePictureExpiration = useCallback(() => {
        localStorage.setItem(
            USER_PICTURE_KEY,
            addDays(Date.now(), 7).getTime().toString(),
        );
    }, []);

    const shouldHideQuarantineActions = () =>
        user?.companyId === BMG_COMPANY_ID;

    function getExpirations() {
        return [localStorage.getItem(USER_PICTURE_KEY) || 0].map(Number) as [
            number,
        ];
    }

    return {
        ensurePicturesLoaded,
        updateProfilePictureExpiration,
        setUserProfilePicture,
        shouldHideQuarantineActions,
    };
}
